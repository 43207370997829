<script setup lang="ts">
import InputLabel from '@/Components/InputLabel.vue'
import TextInput from '@/Components/TextInput.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { QuestionDataObject } from '@Types'
import { SurveyStore } from '@/Pages/Location/stores/useSurveyStore'
import SwitchCheckBox from '@/Components/SwitchCheckBox.vue'

const hours = ref(0)
const minutes = ref(0)

const props = defineProps<{
    question: QuestionDataObject
    store: SurveyStore
    answer?: string
}>()
const computedComment = computed(() =>
    props.store.commentForQuestion(props.question.id)
)
const comment = ref(computedComment.value ?? '')

const computedAnswer = computed(
    () => props.store.answerForQuestion<string>(props.question.id) ?? ''
)

const computedIncludeInReport = computed(() =>
    props.store.includeInReportForQuestion(props.question.id)
)

const switchModel = ref(computedIncludeInReport.value)

const idHours = computed(
    () => 'hours' + props.question.type + props.question.id
)
const idMinutes = computed(
    () => 'minutes' + props.question.type + props.question.id
)

const modelChanged = () => {
    let answer = (hours.value ?? '00') + ':' + (minutes.value ?? '00')
    props.store.setAnswerForQuestion(props.question.id, answer)
}
const commentChanged = () => {
    props.store.setCommentForQuestion(props.question.id, comment.value)
}
const switchModelChanged = () => {
    props.store.setIncludeInReportForQuestion(
        props.question.id,
        switchModel.value
    )
}
watch(computedAnswer, () => {
    if (!computedAnswer.value) return
    let timeComponents = computedAnswer.value.split(':')
    hours.value = parseInt(timeComponents[0])
    minutes.value = parseInt(timeComponents[1])
})

watch(computedIncludeInReport, () => {
    switchModel.value = computedIncludeInReport.value ?? false
})

watch(computedComment, () => {
    comment.value = computedComment.value ?? ''
})

onMounted(() => {
    if (!computedAnswer.value) return
    let timeComponents = computedAnswer.value.split(':')
    hours.value = parseInt(timeComponents[0])
    minutes.value = parseInt(timeComponents[1])
})

const id = computed(() => 'question_' + props.question.type + props.question.id)
</script>

<template>
    <div class="mb-4 flex flex-col" :id="id">
        <InputLabel :for="idHours" :value="question.text" />
        <div class="mt-2 flex gap-3">
            <InputLabel class="mt-3" value="Uren:" />
            <TextInput
                :id="idHours"
                type="number"
                class="block"
                v-model="hours"
                min="0"
                max="100"
                placeholder="uren"
                @input="modelChanged"
            />
            <InputLabel :for="idMinutes" class="mt-3" value="Minuten:" />
            <TextInput
                :id="idMinutes"
                type="number"
                class="block"
                v-model="minutes"
                min="0"
                max="59"
                placeholder="minuten"
                @input="modelChanged"
            />
        </div>
        <div class="mt-2 flex flex-row space-x-2">
            <textarea
                type="text"
                class="col-span-2 block flex-grow rounded border-gray-200"
                v-model="comment"
                placeholder="Opmerking"
                @input="commentChanged"
            />
            <div class="mt-2 flex flex-col">
                <InputLabel
                    class="flex items-center justify-start"
                    for="report-switch"
                    value="Rapportage"
                />
                <div class="my-auto flex justify-start">
                    <SwitchCheckBox
                        id="report-switch"
                        v-model="switchModel"
                        @update:modelValue="switchModelChanged"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
