<template>
    <Switch
        v-model="model"
        :class="model ? 'bg-primary' : 'bg-gray-300'"
        class="relative inline-flex h-6 w-11 items-center rounded-full"
    >
        <span class="sr-only">Enable notifications</span>
        <span
            :class="modelValue ? 'translate-x-5.5' : 'translate-x-0.5'"
            class="inline-block h-5 w-5 transform rounded-full bg-white transition"
        />
    </Switch>
</template>

<script setup lang="ts">
import { Switch } from '@headlessui/vue'

const model = defineModel<boolean>({ required: true })
</script>
